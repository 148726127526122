import {getAxiosConfig} from "~/store/constants/api-url";

export default ($axios) => ({
	async delete({ accountId, documentId }) {
		return $axios.$delete(`/accounts/${accountId}/documents/${documentId}`,
			getAxiosConfig()
		)
	},

	async fetch({ accountId, orderId }) {
		return $axios.$get(`/accounts/${accountId}/productionorders/${orderId}/documents`,
			getAxiosConfig()
		)
	},

	fetchIncidentDocuments({ accountId, orderId, incidentId }) {
		return $axios.$get(`/accounts/${accountId}/productionorders/${orderId}/incidents/${incidentId}/documents`,
			getAxiosConfig()
		)
	},

	getSingle({ accountId, documentId }) {
		return $axios.$get(`/accounts/${accountId}/documents/${documentId}`
		)
	},

	uploadIncidentDocument({ accountId, orderId, incidentId, data }) {
		return $axios.$post(`/accounts/${accountId}/productionorders/${orderId}/incidents/${incidentId}/documents`,
			data,
			getAxiosConfig()
		)
	},
})
