import {defineStore} from 'pinia'
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import {isAxiosError} from "axios";
import {useAccountStore} from "~/stores/account";
import {AccountApi} from "~/gen/openapi/portalService";
import documentsApi from "~/api/documents.api";
import {useOrderService} from "~/services/order";

interface State {
    errorMessage: string,
    isFetching: boolean,
    items: Array<any>,
}

export const useDocumentsStore = defineStore('documents', {
  state: (): State => ({
      errorMessage: '',
      isFetching: false,
      items: [],
	}),
	getters: {
        itemsSorted(state) {
            return reverse(sortBy(state.items, 'LastModifiedDate'))
        },
	},
	actions: {
        async fetch() {
            const accountStore = useAccountStore()
            const accountId = accountStore.activeAccount

            if (!accountId) {
                return
                // throw new Error('Missing accountId')
            }

            this.isFetching = true
            const {$config, $axios} = useNuxtApp()

            const accountApi = new AccountApi(undefined, $config.public.apiBaseHost, $axios);
            try {
                // @ts-ignore
                this.items = (await accountApi.accountGetAccountDocuments(accountId)).data
            } catch (e: any) {
                this.items = []
                if (isAxiosError(e) && e.response) {
                    this.errorMessage = `${e.response.status}: ${e.response.statusText}`
                } else {
                    this.errorMessage = e.toString()
                }

                throw e
            } finally {
                this.isFetching = false
            }
        },
        async delete(orderService: any, documentId: any) {
            const accountStore = useAccountStore()
            const accountId = accountStore.activeAccount

            if (!documentId || !accountId) {
                return
            }

            const {$axios} = useNuxtApp()

            await documentsApi($axios).delete({ accountId, documentId })
            orderService.removeDocument(documentId)
        },
	}
})
